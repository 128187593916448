import { AuthorProfile, Divider, Typography } from "@sphtech/dmg-design-system";
import {
  AuthorProfileData,
  TeaserDataApiResponse,
} from "@src/app/types/ServerCustomContext";
import { FetchError } from "@src/app/utils/swr";
import cx from "classnames";
import { ReactNode } from "react";
import useSWRInfinite from "swr/infinite";

import styles from "./authorprofilelayout.module.css";
import AuthorProfileTeaserLayout from "./AuthorProfileTeaserLayout";

type Props = {
  context: AuthorProfileData;
};

const { Heading } = Typography;
function AuthorProfileLayout({ context }: Props): ReactNode {
  const getLatestStoryByAuthorUrl = (
    _: number,
    prev?: TeaserDataApiResponse,
  ) => {
    if (!context.id) return;
    const params = [
      `id=${context.id}`,
      prev?.endCursor && `after=${encodeURIComponent(prev.endCursor)}`,
    ]
      .filter(Boolean)
      .join("&");
    return `/_plat/api/teaser/by_author?${params}`;
  };

  const { data, error, size, setSize } = useSWRInfinite<
    TeaserDataApiResponse,
    FetchError
  >(getLatestStoryByAuthorUrl, {
    revalidateFirstPage: false,
    initialSize: 1,
  });

  const hasMoreData = !!data?.[size - 1]?.endCursor;
  const isLoadingMore = size > 1 && typeof data?.[size - 1] === "undefined";

  const loadMore = hasMoreData
    ? () => {
        void setSize((size) => (size += 1));
      }
    : undefined;

  return (
    <div className={cx(styles.bodyContainer, styles.gutter)}>
      <div className={styles.title}>
        <Heading.SectionTitle>Profile</Heading.SectionTitle>
      </div>
      <div className={styles.details}>
        <AuthorProfile {...context} lineClamp={false} />
      </div>
      <Divider size="full" orientation="horizontal" />

      {!error && (
        <AuthorProfileTeaserLayout
          data={data}
          isLoadingMore={isLoadingMore}
          loadMore={loadMore}
        />
      )}
    </div>
  );
}

export default AuthorProfileLayout;
