import { configLatestStory, configSearch, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { LatestArticle, Loader } from "@sphtech/dmg-design-system";
import { useInView } from "@src/app/components/hooks/hook";
import { TeaserDataApiResponse } from "@src/app/types/ServerCustomContext";
import { LOAD_MORE_TEXT } from "@src/pubs/common/config";
import cx from "classnames";
import { ReactNode, useEffect } from "react";

import styles from "./authorprofilelayout.module.css";

type Props = {
  data?: TeaserDataApiResponse[];
  isLoadingMore: boolean;
  loadMore?: () => void;
};

function AuthorProfileTeaserLayout({
  data,
  isLoadingMore,
  loadMore,
}: Props): ReactNode {
  const { ref, inView } = useInView({ threshold: 0.1 });
  useEffect(() => {
    if (inView && loadMore) {
      loadMore();
    }
  }, [inView, loadMore]);

  return (
    <>
      {!!data?.length && (
        <section className={styles.section} ref={ref}>
          {data.map((data) => {
            if (!data?.results?.length) {
              return;
            }
            return data.results.map((teaser, index) => (
              <LatestArticle
                key={index}
                {...teaser}
                imageSize="small"
                mode={configLatestStory.mainContentBlockMode}
                placeholders={placeholders}
                displayPublishDate={configTeaser.publishDate}
              />
            ));
          })}
        </section>
      )}
      {(loadMore || isLoadingMore) && (
        <div
          ref={ref}
          className={cx(styles.bodyContainer, styles.gutterBottom)}
        >
          {isLoadingMore && (
            <Loader label={configSearch.loadMoreText || LOAD_MORE_TEXT} />
          )}
        </div>
      )}
    </>
  );
}

export default AuthorProfileTeaserLayout;
