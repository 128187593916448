import { createContext, useContext } from "react";

export type DesignContextType = {
  button?: {
    primary?: string | string[];
    secondary?: string | string[];
  };
  divider?: {
    secondary?: string | string[];
  };
  homeContentBlockB?: {
    contentBlockContainer?: string | string[];
  };
  magazineListing?: {
    border?: string | string[];
    divider?: string | string[];
  };
};

const CustomClassContext = createContext<DesignContextType | null>(null);

const useCustomClassContext = () => useContext(CustomClassContext);

export { useCustomClassContext, CustomClassContext };
