import ContentHub from "@pages/ContentHub/ContentHub";
import Fallback from "@pages/Fallback/Fallback";
import HomePage from "@pages/Home/Home";
import LatestStory from "@pages/LatestStory/LatestStory";
import NewsletterSignup from "@pages/NewsletterSignup/NewsletterSignup";
import Search from "@pages/Search/Search";
import { StoryPage } from "@pages/Story/Story";
import { StoryOrSubsectionPage } from "@pages/StoryOrSubsection/StoryOrSubsection";
import Tag from "@pages/Tag/Tag";
import { configSite } from "@pub/config";
import { pubRoutes } from "@pub/routes";
import { fetchContext, ResponseType } from "@sphtech/web2-core/ssr";
import { StaticOrCategory } from "@src/app/pages/StaticOrCategory";
import { RouteObject } from "react-router-dom";

import App from "./App";
import ErrorLayout from "./layouts/ErrorLayout/ErrorLayout";
import RouteErrorElement from "./layouts/ErrorLayout/RouteErrorElement";
import { AuthorProfile } from "./pages/AuthorProfile/AuthorProfile";

const errorElement = import.meta.env.PROD ? <RouteErrorElement /> : undefined;

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      ...pubRoutes,
      {
        path: "/latest-stories",
        element: <LatestStory />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/article/:id",
        element: <StoryPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        index: true,
        element: <HomePage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/tag/:tag",
        element: <Tag />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/authors/:author",
        element: <AuthorProfile />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/topics",
        element: (
          <ErrorLayout
            response={{
              type: ResponseType.CLIENT_ERROR,
              statusCode: 404,
            }}
          />
        ),
        loader: fetchContext,
      },
      {
        path: "/topics/:slug",
        element: <ContentHub />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/search",
        element: <Search />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/newsletter-signup",
        element: <NewsletterSignup />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/:section",
        element: <StaticOrCategory />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/:section/:storySlugOrSubsection",
        element: <StoryOrSubsectionPage />,
        errorElement,
        loader: fetchContext,
      },
      ...(configSite.sectionLevel3
        ? [
            {
              path: "/:section/:subsection/:storySlugOrSubsubsection",
              element: <StoryOrSubsectionPage />,
              errorElement,
              loader: fetchContext,
            },
            {
              path: "/:section/:subsection/:subsubsection/:storySlug",
              element: <StoryPage />,
              errorElement,
              loader: fetchContext,
            },
          ]
        : [
            {
              path: "/:section/:subsection/:storySlug",
              element: <StoryPage />,
              errorElement,
              loader: fetchContext,
            },
          ]),
      {
        path: "*",
        element: <Fallback />,
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);
