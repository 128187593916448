import PubBaseLayout from "@pub/BaseLayout";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import AuthorProfileLayout from "@src/app/layouts/AuthorProfileLayout/AuthorProfileLayout";
import ErrorLayout from "@src/app/layouts/ErrorLayout/ErrorLayout";
import { AuthorProfileData } from "@src/app/types/ServerCustomContext";

export function AuthorProfile() {
  const routeContext: CustomContext<
    TRouteWithRedirect<AuthorProfileData, string>
  > = useRouteContext();

  const { context: response } = routeContext;
  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  return (
    <PubBaseLayout>
      <AuthorProfileLayout context={response.payload} />
    </PubBaseLayout>
  );
}
