import AboutUsPage from "@pub/AboutUs/AboutUs";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";

import Category from "../Category/Category";

export function StaticOrCategory() {
  const routeContext: CustomContext<TRouteWithRedirect<unknown, unknown>> =
    useRouteContext();
  const { context: response } = routeContext;

  if (
    response.type === ResponseType.SUCCESS &&
    Object.prototype.hasOwnProperty.call(response.payload, "processedElements")
  ) {
    return <AboutUsPage />;
  }
  return <Category />;
}
