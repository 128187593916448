import { Fragment, ReactNode } from "react";
//import css
import styles from "./magazinelisting.module.css";
//import carousel
import Carousel from "../../Utility/Carousel/Carousel";
import { BlackArrowLeft, BlackArrowRight } from "../../Utility/Carousel/icon";
//import utility
import * as Typography from "../../Element/Typography";
import Divider from "../../Element/Divider/divider";
import Button from "../../Element/Button";
import ResponsiveImage from "../../Element/ResponsiveImage";
import Icon from "../../Element/Icon/icon";
import { SectionSubHeading } from "../../Block/SectionHeading/SectionHeading";
import { useMediaQuery } from "../../../hooks";
import { useCustomClassContext } from "../../../hooks/useCustomClassContext/CustomClassContext";
import cx from "classnames";

const { Heading } = Typography;
const { SubHeading3 } = Heading;

export type MagazineListingProps = {
  title: string;
  /**
   * Set this to true if you want to display a divider
   * between the carousel and subscription info
   */
  midDivider?: boolean;
  /**
   * This prop is use to configure the subscription info next to the carousel.
   * It expects an object with the following
   * attributes {subHeading: string, description: string;}
   */
  subscriptionInfo: {
    subHeading: string;
    description: string;
  };
  /**
   * This prop is use to configure the subscription CTA button. It expects an
   * object with the following attributes {
  url: "https://www.mynewsstand.online/product/harpers-bazaar-singapore",
  label: "Subscribe",
  variant: "refer to button element for more info",
  icon: "refer to button element for more info",
  iconPlacement: "refer to button element for more info";}
   */
  subscriptionCta: {
    url: string;
    label: string;
    variant?: "primary" | "secondary";
    icon?: ReactNode;
    iconPlacement?: "before" | "after";
  };
  carousel?: {
    items: {
      image: string;
      srcWidth: number;
      srcHeight: number;
      alt: string;
      caption: string;
      url: string;
    }[];
    noItemsInFrame?: number;
  };
  headingDivider?: boolean;
};

/**
 * This is our magazine listing component
 * @param MagazineListingProps
 * @returns
 */
function MagazineListing({
  title,
  subscriptionInfo,
  subscriptionCta,
  midDivider = false,
  carousel,
  headingDivider,
}: MagazineListingProps): ReactNode {
  const mobileUp = useMediaQuery("screen and (min-width: 1000px)");
  const customClass = useCustomClassContext();

  const Next = <Icon>{BlackArrowRight}</Icon>;
  const Prev = <Icon>{BlackArrowLeft}</Icon>;
  const width = mobileUp ? undefined : 250;

  return (
    <section className={styles.magazinelistingContainer}>
      <SectionSubHeading title={title} withDivider={headingDivider} />

      {/* Magazine issues display in carousel */}
      {carousel && (
        <div className={styles.magazinelistingIssueContainer}>
          <div
            className={styles.magazinelistingCarousel}
            style={{
              width,
            }}
          >
            <Carousel
              itemsLength={carousel.items?.length}
              nextNav={Next}
              prevNav={Prev}
              noItemsInFrame={carousel.noItemsInFrame ?? 3}
              carouselContainerStyle={styles.carouselContainer}
              arrowStyle={{
                next: styles.nextArrow,
                prev: styles.prevArrow,
              }}
            >
              <div className={styles.cardWrapper}>
                {carousel.items.map(
                  (
                    { caption, image, url, alt, srcWidth, srcHeight },
                    index: number,
                  ) => {
                    return (
                      <div key={index} className={styles.card}>
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener"
                          className={styles.issueImage}
                        >
                          <ResponsiveImage
                            src={image}
                            alt={alt}
                            srcWidth={srcWidth}
                            srcHeight={srcHeight}
                            displayWidth={250}
                            fill="contain"
                            fillAspectRatio={3 / 4}
                            loading="lazy"
                          />
                        </a>

                        <div className={styles.cardBody}>
                          <a
                            href={url}
                            className={styles.issueCaption}
                            target="_blank"
                          >
                            <SubHeading3>{caption}</SubHeading3>
                          </a>
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            </Carousel>
          </div>
        </div>
      )}
      <div
        className={cx(
          styles.subscriptionContainer,
          customClass?.magazineListing?.border,
        )}
      >
        {midDivider && (
          <div className={styles.midDivider}>
            <Divider orientation="horizontal" size="full" />
            <span
              className={cx(
                styles.midDividerText,
                customClass?.magazineListing?.divider,
              )}
            >
              OR
            </span>
          </div>
        )}

        {/* Subscription related stuff */}
        <div className={styles.subscriptionInfoContainer}>
          {subscriptionInfo && (
            <Fragment>
              <SubHeading3>{subscriptionInfo.subHeading}</SubHeading3>

              <p className={styles.description}>
                {subscriptionInfo.description}
              </p>
            </Fragment>
          )}

          {subscriptionCta && (
            <div className={styles.subscriptionBtn}>
              <Button
                href={subscriptionCta.url}
                target="_blank"
                variant={subscriptionCta.variant ?? "primary"}
                icon={<>{subscriptionCta.icon}</>}
                iconPlacement={subscriptionCta.iconPlacement ?? "after"}
              >
                {subscriptionCta.label}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default MagazineListing;
